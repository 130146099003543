import fetchMatches from "@/game-eft/fetches/matches.mjs";
import fetchMatchlist from "@/game-eft/fetches/matchlist.mjs";

export default async function fetchData([profileId, gameId]: Array<string>) {
  await Promise.all([
    fetchMatches([gameId]),
    (async () => {
      const matchlist = await fetchMatchlist({ profileId, offset: 10 });
      await fetchMatches(matchlist.map((i) => i.gameId));
    })(),
  ]);
}
